import escapeHTML from "escape-html";
import { isObject, isPlainObject } from "lodash";
/**
 * 메타 데이터를 안전하게 처리하는 함수
 * 사용자값을 이스케이프 처리하여 XSS 공격을 방지합니다.
 *
 * @param metaArray - 메타 데이터 배열
 * @returns 안전하게 처리된 메타 데이터 배열
 */
export function safeMeta<T>(metaArray: T[]): T[] {
  return metaArray.map((item) => escapeValue(item));
}

const KEYS_TO_ESCAPE = [
  "content",
  "title",
  "description",
  "name",
  "headline",
  "text",
];

function escapeValue<T>(value: T): T {
  if (typeof value === "string") {
    return escapeHTML(value) as T;
  }

  if (Array.isArray(value)) {
    return value.map((item) => escapeValue(item)) as T;
  }

  if (isObject(value)) {
    const sanitizedObj: Record<string, unknown> = {};

    for (const [key, nestedValue] of Object.entries(value)) {
      if (
        Array.isArray(nestedValue) ||
        isPlainObject(nestedValue) ||
        KEYS_TO_ESCAPE.includes(key)
      ) {
        sanitizedObj[key] = escapeValue(nestedValue);
      } else {
        sanitizedObj[key] = nestedValue;
      }
    }

    return sanitizedObj as T;
  }

  return value;
}
